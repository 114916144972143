import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql /*, withPrefix */ } from 'gatsby'
// import { join } from 'path'

import config from '../../config'

const getSchemaOrgJSONLD = ({
  isBlogPost,
  url,
  title,
  // image,
  description,
  datePublished
}) => {
  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url,
      name: title,
      alternateName: config.title
    }
  ]

  return isBlogPost
    ? [
        ...schemaOrgJSONLD,
        {
          '@context': 'https://tangibly.tech',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              item: {
                '@id': url,
                name: title
                // image
              }
            }
          ]
        },
        {
          '@context': 'https://tangibly.tech',
          '@type': 'BlogPosting',
          url,
          name: title,
          alternateName: config.title,
          headline: title,
          // image: {
          //   '@type': 'ImageObject',
          //   url: image
          // },
          description,
          author: {
            '@type': 'Company',
            name: 'Tangibly.tech'
          },
          publisher: {
            '@type': 'Organization',
            url: 'https://tangibly.tech',
            // logo: withPrefix('img/' + config.logo),
            name: 'Tangibly.tech'
          },
          mainEntityOfPage: {
            '@type': 'WebSite',
            '@id': config.siteUrl
          },
          datePublished
        }
      ]
    : schemaOrgJSONLD
}

function SEO({
  description,
  lang,
  meta,
  keywords,
  title,
  titleTemplate,
  isBlogPost,
  postData,
  url
}) {
  const _titleTemplate = titleTemplate
    ? () => titleTemplate
    : data => `%s | ${data.site.siteMetadata.title}`
  const postMeta = postData.frontmatter || {}
  // const image =
  //   isBlogPost && postMeta.image && withPrefix('img/' + postMeta.image)
  const datePublished = isBlogPost ? postMeta.date : false
  const schemaOrgJSONLD = getSchemaOrgJSONLD({
    isBlogPost,
    url: url || config.siteUrl,
    title,
    // image,
    description,
    datePublished
  })

  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description
        return (
          <Helmet
            htmlAttributes={{
              lang
            }}
            title={title}
            titleTemplate={_titleTemplate(data)}
            meta={
              [
                {
                  name: `description`,
                  content: metaDescription
                },
                {
                  property: `og:title`,
                  content: title
                },
                {
                  property: `og:description`,
                  content: metaDescription
                },
                {
                  property: `og:type`,
                  content: `website`
                }
                /*{
                  name: `twitter:card`,
                  content: `summary`
                },
                {
                  name: `twitter:creator`,
                  content: data.site.siteMetadata.author
                },
                {
                  name: `twitter:title`,
                  content: title
                },
                {
                  name: `twitter:description`,
                  content: metaDescription
                },
                {
                  name: `google-site-verification`,
                  content: `y18JZNcIGtiVTbCn_DEaH-5w2Oq6IRKZlna83hhR9is`
                }*/
              ]
                .concat(
                  keywords.length > 0
                    ? {
                        name: `keywords`,
                        content: keywords.join(`, `)
                      }
                    : []
                )
                .concat(meta)
              /*.concat(
                image
                  ? [{
                      property: `og:image`,
                      content: image
                    }, {
                      name: `twitter:image`,
                      content: image
                    }, {
                      name: 'image',
                      content: image
                    }]
                  : []
              )*/
            }
          >
            {/* Schema.org tags */}
            <script type="application/ld+json">
              {JSON.stringify(schemaOrgJSONLD)}
            </script>
            {isBlogPost ? <meta property="og:type" content="article" /> : null}
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  isBlogPost: false,
  postData: {}
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  titleTemplate: PropTypes.string,
  isBlogPost: PropTypes.bool,
  url: PropTypes.string,
  postData: PropTypes.shape({
    frontmatter: PropTypes.any,
    excerpt: PropTypes.any
  })
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
