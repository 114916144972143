module.exports = {
  title: `Tangibly.tech`,
  // blogTitle: `The Nomad's Cruise`,
  author: `Tangibly.tech`,
  description: `Mobile, AI and Data solutions providers for market leaders`,
  // is used in rss
  // blogDescription: `Andrey Teologov's blog. Life, engineering, product development, music`,
  siteUrl: `https://tangibly.tech`
  // social: {
  //   twitter: `ateologov`
  // },
  // will do via withPrefix() to load it
  // logo: 'teologov-logo.png'
}
