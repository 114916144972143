import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import PropTypes from 'prop-types'
import {
  Navbar,
  Hero,
  Container,
  Section,
  Content,
  Heading
} from 'react-bulma-components'
// import { ChevronLeft, ChevronRight } from 'react-feather'
import cx from 'classnames'
import CookieConsent from 'react-cookie-consent'

import './styles/base.scss'

import Footer from './footer'

const isActive = (pathname = '', validPathnames = []) =>
  cx({ 'is-active': validPathnames.includes(pathname) })

class IndexLayout extends React.Component {
  // eslint-disable-next-line
  state = {
    open: false
  }

  getWindow() {
    const windowGlobal = typeof window !== 'undefined' && window
    return windowGlobal
  }

  componentDidMount() {
    const window = this.getWindow()
    if (window) {
      window.decodeSupportURL = () => {
        const encoded = 'ifmmpAubohjcmz/ufdi'
        let link = 'mailto:'
        for (let i = 0; i < encoded.length; i++)
          link += String.fromCharCode(encoded.charCodeAt(i) - 1)

        return link
      }
    }
  }

  render() {
    const {
      location: { pathname },
      children
    } = this.props

    const navbarProps = {}
    if (this.props.navbarIsFixed) {
      navbarProps.fixed = 'top'
      navbarProps.style = { backgroundColor: '#FFF' }
    }

    /*return (
      <Hero size="fullheight">


        <Hero.Body className="is-paddingless"></Hero.Body>
        <Hero.Footer>

        </Hero.Footer>
      </Hero>
    )*/
    return (
      <StaticQuery
        query={graphql`
          query SiteImageQuery {
            tangiblyLogo: file(
              absolutePath: { regex: "/tangibly-logo-header-v1.png/" }
            ) {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        `}
        render={data => {
          const navbar = (
            <>
              <Navbar.Brand style={{ marginLeft: 0 }}>
                <Navbar.Item
                  renderAs="span"
                  style={{ width: '8rem', marginTop: '.7rem' }}
                  className="logo-wrapper"
                >
                  <Link to="/">
                    <Image
                      fluid={data.tangiblyLogo.childImageSharp.fluid}
                      style={{ position: 'inherit' }}
                    />
                  </Link>
                </Navbar.Item>
                <Navbar.Burger
                  active={this.state.open}
                  onClick={() => {
                    this.setState({
                      open: !this.state.open
                    })
                  }}
                />
              </Navbar.Brand>
              <Navbar.Menu active={this.state.open} className="centered">
                <Navbar.Container position="end">
                  <Link
                    to="/"
                    className={[isActive(pathname, ['/']), 'navbar-item'].join(
                      ' '
                    )}
                  >
                    Home
                  </Link>
                  <Link
                    to="/portfolio"
                    className={[
                      isActive(pathname, ['/portfolio']),
                      'navbar-item'
                    ].join(' ')}
                  >
                    Portfolio
                  </Link>
                  <Link
                    to="/stack"
                    className={[
                      isActive(pathname, ['/stack']),
                      'navbar-item'
                    ].join(' ')}
                  >
                    Technologies
                  </Link>
                  <Link
                    to="/about"
                    className={[
                      isActive(pathname, ['/about']),
                      'navbar-item'
                    ].join(' ')}
                  >
                    About us
                  </Link>
                  <Link
                    to="/careers"
                    className={[
                      cx({ 'is-active': pathname.startsWith('/careers') }),
                      'navbar-item'
                    ].join(' ')}
                  >
                    Careers
                  </Link>
                  <div className="navbar-item">
                    <a
                      href="javascript:window.location.href=decodeSupportURL()"
                      className="button is-primary"
                      style={{ fontWeight: 'bold', fontSize: '0.7rem' }}
                    >
                      CONTACT US
                    </a>
                  </div>
                  {/*<Link to="/" className="navbar-item">
                    why
                  </Link>
                  <Link to="/" className="navbar-item">
                    what
                  </Link>
                  <Link to="/" className="navbar-item">
                    who
                  </Link>
                  <Link to="/" className="navbar-item">
                    contact
                  </Link>
                  */}
                </Navbar.Container>
              </Navbar.Menu>
            </>
          )
          const menu = (
            <Hero.Head renderAs="header">
              <header className="nav">
                <div className="container">
                  <Navbar active={this.state.open} {...navbarProps}>
                    {this.props.navbarIsFixed ? (
                      <Container>{navbar}</Container>
                    ) : (
                      <>{navbar}</>
                    )}
                  </Navbar>
                </div>
              </header>
            </Hero.Head>
          )
          let body

          if (this.props.bodyInContainer) {
            body = (
              <Container style={{ alignSelf: this.props.alignBody }}>
                {children}
              </Container>
            )
          } else {
            body = <>{children}</>
          }

          return (
            <>
              {menu}
              {body}
              <Footer />
              <CookieConsent
                disableStyles
                enableDeclineButton
                location="bottom"
                buttonText="ACCEPT"
                declineButtonText="REJECT"
                cookieName="tangibly-cookie-consent"
                buttonClasses="button is-primary accept-button"
                declineButtonClasses="button"
                containerClasses="tangibly-cookie-consent"
                contentClasses="consent-inner is-size-6"
                expires={365}
              >
                <Section style={{ padding: '1.5rem' }}>
                  <Content>
                    <Heading renderAs="h2" size={4}>
                      We use cookies
                    </Heading>
                    The{' '}
                    <a className="link" css={{ color: '#5B59FB' }}>
                      tangibly.tech
                    </a>{' '}
                    website uses cookies to improve the quality of our website.
                    You can accept or refuse our cookies by clicking on the
                    buttons below or by visiting our{' '}
                    <Link
                      to="/cookie-policy"
                      className="link"
                      css={{ color: '#5B59FB' }}
                    >
                      Cookie policy page
                    </Link>
                    . A default &apos;no consent&apos; option applies in case no
                    choice is made and refusal will not limit your user
                    experience. If you would like to know more about our cookie
                    policy, please click on the {'"'}More information{'"'}{' '}
                    button below.
                  </Content>
                </Section>
              </CookieConsent>
            </>
          )
        }}
      />
    )
  }
}

IndexLayout.propTypes = {
  location: PropTypes.object,
  title: PropTypes.string,
  children: PropTypes.node,
  alignBody: PropTypes.string,
  bodyInContainer: PropTypes.bool,
  navbarIsFixed: PropTypes.bool
}

IndexLayout.defaultProps = {
  alignBody: 'middle',
  bodyInContainer: true,
  navbarIsFixed: true
}

export default IndexLayout
