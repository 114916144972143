import React, { Component } from 'react'
import { Content, Section, Icon } from 'react-bulma-components'
import { Link } from 'gatsby'

import LocationIcon from '../../../../content/assets/icons/location.svg'
// import EmailIcon from '../../../../content/assets/icons/email.svg'

import './styles.scss'

class Footer extends Component {
  state = {
    seoDescriptionOpened: false
  }

  toggleSeoContent(e) {
    e.preventDefault()
    this.setState({ seoDescriptionOpened: !this.state.seoDescriptionOpened })
  }

  render() {
    return (
      <footer className="footer">
        <Section
          className="has-text-centered"
          style={{ padding: '3rem 1.5rem 1rem' }}
        >
          <Content style={{ display: 'flex', justifyContent: 'center' }}>
            <div css={{ display: 'flex', flexDirection: 'column' }}>
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Icon>
                  <LocationIcon />
                </Icon>
                <span
                  css={{
                    color: '#FFF',
                    fontWeight: '400',
                    fontSize: '1.25rem',
                    marginLeft: '.5rem'
                  }}
                >
                  Location
                </span>
              </div>
              <div css={{ paddingLeft: '0.5rem' }}>Estonia, Tallinn</div>
              <div css={{ fontSize: '0.9rem' }}>
                Harju maakond, Tallinn, Kesklinna linnaosa, Sakala tn 7-2, 10141
              </div>
            </div>
          </Content>
          <div
            css={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <ul
              css={{ listStyle: 'none', fontSize: '0.75rem', display: 'flex' }}
            >
              <li css={{ marginRight: '1rem' }}>
                {' '}
                © {new Date().getFullYear()} Tangibly OU.
              </li>
              <li css={{ marginRight: '1rem' }}>
                <Link
                  class="is-link is-outlined is-small"
                  to="/privacy-policy"
                  css={{ ':hover': { color: 'white' } }}
                >
                  Privacy
                </Link>
              </li>
              <li css={{ marginRight: '1rem' }}>
                <Link
                  class="is-link is-outlined is-small"
                  to="/cookie-policy"
                  css={{ ':hover': { color: 'white' } }}
                >
                  Cookies
                </Link>
              </li>
            </ul>
          </div>
        </Section>
      </footer>
    )
  }
}

export default Footer
